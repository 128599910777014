import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 800px;
  margin: 50px auto;
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
`;

const Heading = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 28px;
  font-weight: bold;
`;

const SubHeading = styled.h3`
  margin-top: 20px;
  color: #ff4500;
  font-size: 22px;
  font-weight: bold;
`;

const Paragraph = styled.p`
  margin: 10px 0;
  line-height: 1.6;
  color: #555;
`;

const SuccessMessage = styled.p`
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  text-align: center;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ff4500;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  &:hover {
    background-color: #e03d00;
  }
`;

const TermsOfService = () => {
  return (
    <Container>
      <Heading>Kullanım Koşulları</Heading>
      
      <SubHeading>1. Giriş</SubHeading>
      <Paragraph>Bu kullanım koşulları, TkyExpress'nın web sitesini / mobil uygulamasını kullanırken geçerli olan şartları ve koşulları içermektedir.</Paragraph>
      
      <SubHeading>2. Hizmetlerin Kullanımı</SubHeading>
      <Paragraph>TkyExpress tarafından sunulan hizmetler yalnızca yasal amaçlar için kullanılabilir.</Paragraph>
      
      <SubHeading>3. Kullanıcı Yükümlülükleri</SubHeading>
      <Paragraph>Kullanıcılar, web sitesini kullanırken doğru ve güncel bilgiler sağlamalı ve siteyi kötüye kullanmamalıdır.</Paragraph>
      
      <SubHeading>4. Fikri Mülkiyet Hakları</SubHeading>
      <Paragraph>Bu web sitesinde yer alan tüm içerikler, TkyExpress'nın mülkiyetindedir ve telif hakkı yasaları tarafından korunmaktadır.</Paragraph>
      
      <SubHeading>5. Sorumluluk Sınırları</SubHeading>
      <Paragraph>TkyExpress, web sitesinin kullanımı sırasında meydana gelebilecek doğrudan veya dolaylı zararlardan sorumlu değildir.</Paragraph>
      
      <SubHeading>6. Değişiklikler</SubHeading>
      <Paragraph>TkyExpress, bu kullanım koşullarını herhangi bir zamanda önceden bildirimde bulunmaksızın değiştirme hakkını saklı tutar.</Paragraph>
      
      <SubHeading>7. İletişim</SubHeading>
      <Paragraph>Kullanım koşulları hakkında sorularınız için bizimle iletişime geçebilirsiniz:</Paragraph>
      <ul>
        <li>E-posta: destek@tkyexpress.com</li>
        <li>Telefon: 0850 611 06 69</li>
        <li>Adres: Üçevler, Bahçe Yolu Cd. No:32, 34514 Esenyurt/İstanbul</li>
      </ul>
    </Container>
  );
};

export default TermsOfService;
