import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.text};
  padding: 2rem;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;

  a {
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const FooterLink = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
const FooterLinkText = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
  margin: 0 0.5rem;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <p>
        © {new Date().getFullYear()} <FooterLink href="https://nahrimed.com/" target="_blank" rel="noopener noreferrer">Nahrimed</FooterLink>. All rights reserved.
      </p>
      <p>
        <FooterLinkText href="/privacy-policy">Gizlilik Politikası</FooterLinkText>
        <FooterLinkText href="/data-owner-application-form">Veri Sahibi Başvuru Formu</FooterLinkText>
        <FooterLinkText href="/terms-of-service">Kullanım Koşulları</FooterLinkText>
        <FooterLinkText href="/disclosure-text">Aydınlatma Metni</FooterLinkText>
        <FooterLinkText href="/cookie-policy">Çerez Politikası</FooterLinkText>
        <FooterLinkText href="/protection-of-personal-data">Kişisel Verilerinizin Korunması</FooterLinkText>
      </p>
    </FooterContainer>
  );
};

export default Footer;
