import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SliderWrapper = styled.div`
  width: 90%;
  margin: 1rem auto;

  .slick-slide img {
    display: block;
    margin: auto;
    max-height: 400px;
    @media (min-width: 769px) {
      max-height: 600px; /* Masaüstü için daha yüksek yükseklik */
    }
  }

  .slick-dots li button:before {
    color: ${({ theme }) => theme.colors.secondary};
  }

  .slick-prev, .slick-next {
    z-index: 1;
    color: ${({ theme }) => theme.colors.secondary};
  }

  @media (max-width: 768px) {
    width: 100%;
    .slick-slide img {
      max-height: 250px;
    }
  }
`;

const SlideContent = styled.div`
  text-align: center;
  padding: 0.5rem;
  background: #f5f5f5;

  @media (max-width: 768px) {
    padding: 0.25rem;
  }
`;

const SlideImage = styled.img`
  width: 100%;
  object-fit: cover;
`;

const SlideText = styled.div`
  padding: 0.5rem 0;
  color: ${({ theme }) => theme.colors.text};
  font-size: 1.5rem; /* Daha büyük boyut */
  font-weight: bold; /* Kalın yazı stili */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Hoş bir gölge efekti */

  @media (max-width: 768px) {
    font-size: 1.2rem; /* Mobil için daha küçük boyut */
  }
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.primary};
  padding: 1rem;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  width: 40px;
  height: 40px;
  transition: background 0.3s;

  &.slick-prev {
    left: 10px;
  }

  &.slick-next {
    right: 10px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.secondary}; /* Arka plan rengini koru */
    color: ${({ theme }) => theme.colors.primary}; /* Ok rengini koru */
  }

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
    padding: 0.5rem;

    &.slick-prev {
      left: 5px;
    }

    &.slick-next {
      right: 5px;
    }
  }
`;

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Arrow className={className} style={{ ...style, display: 'flex' }} onClick={onClick}>
      ‹
    </Arrow>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <Arrow className={className} style={{ ...style, display: 'flex' }} onClick={onClick}>
      ›
    </Arrow>
  );
};

const CustomSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <SliderWrapper>
      <Slider {...settings}>
        <div>
          <SlideContent>
            <SlideImage src={"/courier1.png"} alt="Slider 1" />
            <SlideText>
              Hızlı ve Güvenilir Teslimat
            </SlideText>
          </SlideContent>
        </div>
        <div>
          <SlideContent>
            <SlideImage src={"/courier2.png"} alt="Slider 2" />
            <SlideText>
              Paketleriniz Güvende
            </SlideText>
          </SlideContent>
        </div>
        <div>
          <SlideContent>
            <SlideImage src={"/courier3.png"} alt="Slider 3" />
            <SlideText>
              Profesyonel Kurye Hizmetleri
            </SlideText>
          </SlideContent>
        </div>
      </Slider>
    </SliderWrapper>
  );
};

export default CustomSlider;
