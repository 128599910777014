import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 800px;
  margin: 50px auto;
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
`;

const Heading = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 28px;
  font-weight: bold;
`;

const SubHeading = styled.h3`
  margin-top: 20px;
  color: #ff4500;
  font-size: 22px;
  font-weight: bold;
`;

const Paragraph = styled.p`
  margin: 10px 0;
  line-height: 1.6;
  color: #555;
`;

const SuccessMessage = styled.p`
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  text-align: center;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ff4500;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  &:hover {
    background-color: #e03d00;
  }
`;

const DisclosureText = () => {

  return (
    <Container>
      <Heading>Aydınlatma Metni</Heading>
      
      <SubHeading>1. Giriş</SubHeading>
      <Paragraph>Bu aydınlatma metni, TkyExpress tarafından 6698 sayılı Kişisel Verilerin Korunması Kanunu ("KVKK") kapsamında kişisel verilerin işlenmesine ilişkin bilgilendirme amacı taşımaktadır.</Paragraph>
      
      <SubHeading>2. İşlenen Kişisel Veriler</SubHeading>
      <Paragraph>Kişisel verileriniz, TkyExpress tarafından çeşitli amaçlarla işlenmektedir. Bu veriler şunlardır:</Paragraph>
      <ul>
        <li>Kimlik Bilgileri</li>
        <li>İletişim Bilgileri</li>
        <li>Finansal Bilgiler</li>
        <li>Hizmet Kullanım Bilgileri</li>
      </ul>
      
      <SubHeading>3. Kişisel Verilerin İşlenme Amaçları</SubHeading>
      <Paragraph>Kişisel verileriniz şu amaçlarla işlenmektedir:</Paragraph>
      <ul>
        <li>Hizmetlerin sunulması ve iyileştirilmesi</li>
        <li>Müşteri ilişkilerinin yönetimi</li>
        <li>Yasal yükümlülüklerin yerine getirilmesi</li>
        <li>Pazarlama ve analiz faaliyetleri</li>
      </ul>
      
      <SubHeading>4. Kişisel Verilerin Aktarımı</SubHeading>
      <Paragraph>Kişisel verileriniz, KVKK'ya uygun olarak üçüncü taraflarla paylaşılabilir:</Paragraph>
      <ul>
        <li>İş ortakları</li>
        <li>Hizmet sağlayıcılar</li>
        <li>Yasal merciler</li>
      </ul>
      
      <SubHeading>5. Kişisel Verilerin Saklanma Süresi</SubHeading>
      <Paragraph>Kişisel verileriniz, ilgili yasal mevzuatta öngörülen süreler boyunca saklanacaktır. Bu sürelerin sonunda, kişisel verileriniz silinecek veya anonim hale getirilecektir.</Paragraph>
      
      <SubHeading>6. Veri Sahibi Hakları</SubHeading>
      <Paragraph>Kişisel veri sahipleri olarak KVKK kapsamında aşağıdaki haklara sahipsiniz:</Paragraph>
      <ul>
        <li>Kişisel verilerin işlenip işlenmediğini öğrenme</li>
        <li>Kişisel veriler işlenmişse buna ilişkin bilgi talep etme</li>
        <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme</li>
        <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme</li>
        <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme</li>
        <li>KVKK'nın 7. maddesinde öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme</li>
        <li>Yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme</li>
        <li>İşlenen verilerin münhasıran otomatik sistemler aracılığıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme</li>
        <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme</li>
      </ul>
      
      <SubHeading>7. İletişim</SubHeading>
      <Paragraph>Aydınlatma metni ile ilgili sorularınız için bizimle iletişime geçebilirsiniz:</Paragraph>
      <ul>
        <li>E-posta: destek@tkyexpress.com</li>
        <li>Telefon: 0850 611 06 69</li>
        <li>Adres: Üçevler, Bahçe Yolu Cd. No:32, 34514 Esenyurt/İstanbul</li>
      </ul>
    </Container>
  );
};

export default DisclosureText;
